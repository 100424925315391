//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    country() {
      return this.$nuxt.$config.COUNTRY || 'br';
    },
    partners() {
      let partners = [];
      if (this.country === 'mx') {
        partners = [
          require('@/assets/images/index1/partners/logo_1.svg'),
          require('@/assets/images/index1/partners/logo_2.png'),
          require('@/assets/images/index1/partners/logo_3.png'),
          require('@/assets/images/index1/partners/logo_5.png'),
          require('@/assets/images/index1/partners/logo_6.png'),
          require('@/assets/images/index1/partners/logo_7.svg'),
          require('@/assets/images/index1/partners/logo_10.png'),
          require('@/assets/images/index1/partners/logo_11.svg'),
          require('@/assets/images/index1/partners/logo_16.png'),
          require('@/assets/images/index1/partners/logo_13.svg'),
          require('@/assets/images/index1/partners/logo_14.svg'),
          require('@/assets/images/index1/partners/logo_15.svg'),
        ];
      } else {
        partners = [
          require('@/assets/images/index1/partners/logo_1.svg'),
          require('@/assets/images/index1/partners/logo_2.png'),
          require('@/assets/images/index1/partners/logo_3.png'),
          require('@/assets/images/index1/partners/logo_4.png'),
          require('@/assets/images/index1/partners/logo_5.png'),
          require('@/assets/images/index1/partners/logo_8.png'),
          require('@/assets/images/index1/partners/logo_9.png'),
          require('@/assets/images/index1/partners/logo_10.png'),
          require('@/assets/images/index1/partners/logo_11.svg'),
          require('@/assets/images/index1/partners/logo_7.svg'),
          require('@/assets/images/index1/partners/logo_16.png'),
          require('@/assets/images/index1/partners/logo_13.svg'),
          require('@/assets/images/index1/partners/logo_14.svg'),
          require('@/assets/images/index1/partners/logo_15.svg'),
          require('@/assets/images/index1/partners/logo_6.svg'),
          require('@/assets/images/index1/partners/logo_17.svg'),
        ];
      }

      this.debug({ name: 'comp partners' }, '%O', partners);
      return partners;
    },
  },
};
