//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MoreButton from '../MoreButton';
export default {
  components: {
    MoreButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    needMoreButtonInMobileVersion: {
      type: Boolean,
    },
  },
  computed: {
    isMx() {
      return this.$nuxt.$config.COUNTRY === 'mx';
    },
    isBr() {
      return this.$nuxt.$config.COUNTRY === 'br';
    },
    professorsBr() {
      return [
        require(`~/assets/images/index1/professors/br/teacher_1.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_2.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_3.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_4.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_5.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_6.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_7.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_8.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_9.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_10.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_11.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_12.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_13.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_14.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_15.jpg`),
        require(`~/assets/images/index1/professors/br/teacher_16.jpg`),
      ];
    },
    professorsMx() {
      return [
        require(`~/assets/images/index1/professors/mx/teacher_1.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_2.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_3.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_4.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_5.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_6.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_7.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_8.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_9.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_10.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_11.jpg`),
        require(`~/assets/images/index1/professors/mx/teacher_12.jpg`),
      ];
    },
  },

  methods: {
    getColumn(columnIdx) {
      let column = [];
      for (
        let professorIdx = columnIdx;
        professorIdx < this.professors.length;
        professorIdx += 4
      ) {
        column.push(this.professors[professorIdx]);
      }

      this.debug(
        { name: 'getColumn' },
        'columnIdx = %d, return = %O',
        columnIdx,
        column,
      );
      return column;
    },

    showMore() {
      this.$refs?.professors?.classList.add('professors--expanded');
    },
  },
};
