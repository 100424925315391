//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PrivacyPolicy from '~/components/common/privacyPolicy';
import Phone from '~/components/common/phone';
import urlencodeFormData from '~/utils/urlencodeFormData';
import addAnyFormFields from '~/utils/addAnyFormFields';
import HintEmail from '../common/hintEmail.vue';
import ViewportMixin from '@/mixins/viewport.mixin';
import { getGRecaptchaScore } from '@/utils/grecaptcha';
import handleError from '@/utils/handleError';
import customerIoMixin from '@/mixins/customerIo.mixin';

export default {
  components: {
    Phone,
    PrivacyPolicy,
    ValidationObserver,
    ValidationProvider,
    HintEmail,
  },
  mixins: [ViewportMixin, customerIoMixin],
  props: {
    prefix: {
      type: String,
      default: 'rt0822',
    },
    disableReactivationLead: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: '',
      email: '',
      phone: '',
      rulesAccepted: true,
      loading: false,
      isFormSubmitted: false,
    };
  },
  computed: {
    isEmailAvailable() {
      return !!this.$app.user?.email && !this.isFormSubmitted;
    },
    isNameAvailable() {
      return !!this.$app.user?.name && !this.isFormSubmitted;
    },
  },
  watch: {
    '$store.state.customerIo.userDataFromCio': {
      handler(dataFromCio) {
        if (!this.$app.user?.email && dataFromCio?.email) {
          this.email = dataFromCio.email;
        }
        if (!this.$app.user?.profile?.phone && dataFromCio?.phone) {
          this.phone = dataFromCio.phone;
        }
        if (!this.$app.user?.name && dataFromCio?.name) {
          this.name = dataFromCio.name;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    setTimeout(async () => {
      this.debug({ name: 'mounted' }, 'call addAnyFormFields');
      await addAnyFormFields.call(this, { ref: this.$refs.form });
    }, 1000);

    const email = this.$app.user?.email;
    const phone = this.$app.user?.profile?.phone;
    const name = this.$app.user?.name;
    if (email) this.email = email.trim();
    if (phone) this.phone = phone.trim();
    if (name) this.name = name.trim();
  },

  methods: {
    async onSubmit() {
      this.loading = true;

      this.$emit('formSubmit');

      const formData = new FormData(this.$refs.form.$el.querySelector('form'));
      formData.append('name', this.name);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('checkbox', this.rulesAccepted);

      const grecaptchaScore = await getGRecaptchaScore.bind(this)();
      formData.append('google_score', grecaptchaScore);

      this.debug(
        { name: 'onSubmit' },
        'data = %O',
        urlencodeFormData(formData),
      );
      try {
        const response = await this.$axios({
          method: 'post',
          url: this.$app.config.current.feedbackUrl,
          data: urlencodeFormData(formData),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        // this.$refs.modal.show('form', 'success', true, this.prefix);

        this.$store.dispatch('modalNotify/OPEN', {
          title: this.$t(`${this.prefix}.form.success.title`),
          description: this.$t(`${this.prefix}.form.success.text`),
          action: this.$t(`${this.prefix}.form.success.button`),
        });

        this.$refs.form?.reset();
        this.name = '';
        this.email = '';
        this.phone = '';

        this.$gtm?.push({
          event: 'autoEvent',
          eventCategory: 'lead',
          eventAction: 'index_footer',
        });

        if (response.status === 200) {
          this.$emit('formSuccess');
        }
      } catch (e) {
        handleError.bind(this)(e);
      } finally {
        this.loading = false;
        this.isFormSubmitted = true;
      }
    },
  },
};
