import { render, staticRenderFns } from "./professors.vue?vue&type=template&id=c3ada1a6&scoped=true&"
import script from "./professors.vue?vue&type=script&lang=js&"
export * from "./professors.vue?vue&type=script&lang=js&"
import style0 from "./professors.vue?vue&type=style&index=0&id=c3ada1a6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3ada1a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/landing_generator/components/lazy-img.vue').default,MoreButton: require('/app/landing_generator/components/MoreButton/index.vue').default})
