//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getEventImages,
  getImagesForOneSize,
} from '@/utils/event/getEventImages';
import SpeakersPreview from './speakersPreview';
import { getTime, getDate } from '@/utils/event/dateTime';
import LazyImg from '@/components/autoscale/lazyImg.vue';

export default {
  components: {
    SpeakersPreview,
    LazyImg,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: 'small',
    },
    badgeOnlineOff: {
      type: Boolean,
      default: false,
    },
    speakersLimit: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      coverBadges: [],
      infoBadges: [],
      images: null,
    };
  },
  computed: {
    /** Чтобы переход правильно отработал - нужно обязательно
     *  указать name у роута
     */
    eventLink() {
      const locale = this.$app.config.current.localeName;
      const name = this.$store.state.eventsList.routeNames.detailed;
      const routeName = `${name}___${locale}`;

      return {
        name: routeName,
        params: {
          slug: this.data.slug,
        },
      };
    },
    computedImageConfig() {
      if (this.$props.size === 'large') {
        return [
          { ext: 'webp', sizes: ['150x150 1x', '300x300 2x'] },
          { ext: 'png', sizes: ['150x150 1x', '300x300 2x'] },
        ];
      }
      return [
        { ext: 'webp', sizes: ['100x100 1x', '200x200 2x'] },
        { ext: 'png', sizes: ['100x100 1x', '200x200 2x'] },
      ];
    },
  },
  watch: {
    // Если при изменении фильтра ивент поменяет положение (например, был маленькой карточкой)
    // А стал большой, то без вотчера картинки не подтянут новый размер
    size() {
      this.getImages();
    },
  },
  created() {
    this.getCoverBadges();
    this.getInfoBadges();
    this.getImages();
  },
  methods: {
    /** На обложке показываем формат (всегда онлайн) и тип ивента */
    getCoverBadges() {
      const badges = [];

      if (!this.badgeOnlineOff) {
        badges.push({ text: 'Online' });
      }

      const type = this.data.type;
      badges.push({ text: this.$t(`webinar.type.${type}`) });

      this.coverBadges = badges;
    },
    /** Под обложкой показываем дату, время ивента и live, если он в эфире */
    getInfoBadges() {
      const badges = [];

      badges.push({ text: getDate(this, this.data.startDateUtc) });
      badges.push({ text: getTime(this, this.data.startDateUtc) });

      const live = this.data.nearestLiveTopic;
      const liveText = this.$t('webinar.topic.status.live');
      if (live) badges.push({ text: liveText, color: 'red' });

      this.infoBadges = badges;
    },
    /** Если ивент в рекомендациях - отправляем GTM событие при клике */
    onClick() {
      this.$emit('click', this.data);

      if (!this.isRecommendation) return;
      this.$gtm?.push({
        event: 'autoEvent',
        eventCategory: 'webinar_click',
        eventAction: 'recommendations',
        eventLabel: this.data.name,
      });
    },
    /** Получить фон и фигуру для обложки
     *  Эти картинки назначаются каждому ивенту по его slug'у
     *  пока у ивента не поменяется слаг - ему будет присвоена одна из картинок
     *  и она не будет меняться
     */
    getImages() {
      const images = getEventImages(this.data);

      // Для разных по размеру карточек определяются разные изображения из ассетов
      // Изначальные значения характерны для карточек с размером small
      let desktopSize = 'small';
      let tabletSize = 'micro';
      let mobileSize = 'micro';

      if (this.size === 'medium') {
        desktopSize = 'medium';
        tabletSize = 'medium';
        mobileSize = 'micro';
      }

      if (this.size === 'large') {
        desktopSize = 'large';
        tabletSize = 'large';
        mobileSize = 'micro';
      }

      const eventImages = {
        desktop: { ...getImagesForOneSize(images, 'card', desktopSize) },
        tablet: { ...getImagesForOneSize(images, 'card', tabletSize) },
        mobile: { ...getImagesForOneSize(images, 'card', mobileSize) },
      };

      this.images = eventImages;
    },
  },
};
